import React from "react";
import { ContentSection, Flex, FlexItem } from "@ryerson/frontend.layout";
import { HoverSlideCardSection, SlideCard } from "../MetalProcessing";
import { Typography } from "@ryerson/frontend.typography";
import { css } from "@emotion/react";
import { useTheme } from "@ryerson/frontend.theme";
import styled from "@emotion/styled";
import { ButtonLink, Link } from "@ryerson/frontend.navigation";

const MetalProcessingDesktop: React.FC<HoverSlideCardSection> = ({ ...props }) => {
	const { theme } = useTheme();

	const HeaderSection = styled.span`
		max-width: 100%;
	`;

	const IndividualCard = styled.div`
		width: 200px;
		position: relative;
		text-align: center;
	`;

	React.useEffect(() => {
		if (typeof window !== undefined) {
			const individualCards = document.querySelectorAll(".individualCard");
			const handleMouseLeave = () => {
				individualCards.forEach((el, index) => {
					el.classList.remove("hoveredCard");
					if (individualCards.length - 1 === index) {
						setTimeout(() => {
							const hovered = document.querySelectorAll(".hoveredCard");
							if (hovered.length === 0) {
								individualCards[0].classList.add("hoveredCard");
							}
						}, 1);
					}
				});
			};
			individualCards.forEach((el) => {
				el.addEventListener("mouseleave", handleMouseLeave);
				el.addEventListener("mouseenter", () => {
					individualCards.forEach((el) => el.classList.remove("hoveredCard"));
					el.classList.add("hoveredCard");
				});
			});

			if (individualCards.length > 0) {
				individualCards[0].classList.add("hoveredCard");
			}
			return () => {
				individualCards.forEach((el) => {
					el.removeEventListener("mouseleave", handleMouseLeave);
				});
			};
		}
	}, []);

	return (
		<>
			<ContentSection type="secondary" vPadding="80px">
				<HeaderSection>
					<Typography
						variant="h1"
						css={css`
							color: ${theme.colors.primary.gray};
							font-size: 56px;
							line-height: 70px;
						`}
					>
						{props.titleGray}
						<Typography
							variant="span"
							css={css`
								color: ${theme.colors.primary.secondaryBrand};
							`}
						>
							{props.titleBlue}
						</Typography>
					</Typography>
				</HeaderSection>
				<Flex
					direction="row"
					justifyContent="flex-start"
					alignItems="center"
					css={css`
						width: 100%;
						height: 328px;
						margin-top: 40px;
						.hoveredCard {
							width:560px;
							.hoverImageContainer{
								background-size:auto 70%;
							}
							.overlay {
								&::before{
									opacity:1;
								}
							}
							.cardDescription {
								visibility:visible;
								height:66px;
								opacity:1;
							}
							.cardTitle {
								height:30px;
								h4{
									color:${theme.colors.primary.header};
								}
							}
						}
							
						}
					`}
				>
					{props.slideCards?.map((slideCards: SlideCard, index: number) => (
						<IndividualCard
							className="individualCard"
							css={css`
								background-image: url(${slideCards.slideCardBackgroundImage});
								background-size: cover;
								background-position: center;
								height: 100%;
								position: relative;
								transition: width 0.25s linear;
							`}
						>
							<div
								className="overlay"
								css={css`
									background: linear-gradient(
										180deg,
										rgba(82, 92, 107, 0.5) -34.76%,
										#525c6b 87.67%
									);
									width: 100%;
									height: 100%;
									position: absolute;
									top: 0px;
									left: 0px;
									z-index: 1;
									&::before {
										background: linear-gradient(
											180deg,
											rgba(255, 255, 255, 0.5) -34.76%,
											#fff 65.64%
										);
										position: absolute;
										top: 0;
										content: "";
										left: 0;
										z-index: 2;
										width: 100%;
										height: 100%;
										opacity: 0;
										transition: opacity 0.25s linear;
									}
								`}
							/>
							<Link to={slideCards.buttonLinkUrl}>
								{" "}
								<Flex
									justifyContent="center"
									alignItems="center"
									direction="column"
									css={css`
										height: 100%;
										position: relative;
										text-align: center;
										z-index: 20;
									`}
								>
									<FlexItem>
										<Flex
											css={css`
												width: 100%;
												position: relative;
												z-index: 20;
											`}
											alignItems="center"
											justifyContent="space-between"
											direction="column"
										>
											<FlexItem
												className="hoverImageContainer"
												css={css`
													height: 180px;
													width: 100%;
													background-image: url(${slideCards.slideCardImage});
													background-size: auto 50%;
													background-position: center;
													background-repeat: no-repeat;
													transition: background-size 0.25s linear;
												`}
											/>
											<FlexItem
												className="cardTitle"
												css={css`
												height: 90px;
												transition:height: 0.25s linear;
											`}
											>
												<Typography
													variant="h4"
													css={css`
														text-align: center;
														color: ${theme.colors.primary.white};
														transition: color 0.25s linear;
														padding-left: 20px;
														padding-right: 20px;
														box-sizing: border-box;
														line-height: 22px;
													`}
												>
													{slideCards.slideCardTitle}
												</Typography>
											</FlexItem>
											<FlexItem
												className="cardDescription"
												css={css`
													visibility: hidden;
													box-sizing: border-box;
													width: 100%;
													height: 0px;
													visibility: hidden;
													opacity: 0;
													transition: visibility 0.25s linear,
														opacity 0.25s linear;
													overflow: hidden;
													padding-top: 8px;
													margin-top: 13px;
												`}
											>
												<Typography
													variant="div"
													css={css`
														text-align: center;
														padding-left: 40px;
														padding-right: 40px;
														font-size: 12px;
													`}
												>
													{slideCards.slideCardBlurb}
												</Typography>
											</FlexItem>
										</Flex>
									</FlexItem>
								</Flex>
							</Link>
						</IndividualCard>
					))}
				</Flex>
				<Typography
					variant="p"
					css={css`
						width: 586px;
						padding-bottom: 12px;
						padding-top: 40px;
					`}
				>
					{props.blurb}
				</Typography>
				<div
					css={css`
						width: calc(100% - 180px);
					`}
				>
					<ButtonLink
						to="/metal-solutions/capabilities/"
						label={props.buttonLabel}
						linkType="internal"
						size="md"
					/>
				</div>
			</ContentSection>
		</>
	);
};

export default MetalProcessingDesktop;
